<template>
  <section class="mainText ">
    <b-container fluid="xl">
        <b-row class="maintText__row">
        <b-col cols="12" class="mainText__mainName side-gap">

          <h2 style="mainText__mainName--maintxtx">
            Okos tippek fesztiválozáshoz
          </h2>
          <p class="hashText">- Digitális biztonsági szempontból</p>
        </b-col>
      </b-row>
        <b-row class="maintText__row">
            <b-col cols="12" lg="6">
            <div class="maintText__txt-content pb-lg-5 pb-2">
                <p class="text">
                A fesztiválok időszaka mindig izgalmas és felejthetetlen élményeket tartogat számunkra, azonban a digitális világ térnyerésével együtt járnak olyan kihívások és előnyök is, amelyekre érdemes odafigyelni és kihasználni, hogy biztonságosan élvezhessük a fesztiválok nyújtotta szórakozást. Ebben a cikkben áttekintünk néhány fontos tippet a digitális biztonság szempontjából, hogy elkerüljük a kellemetlenségeket és gondtalanul élvezhessük a nyári bulikat. </p>
            </div>
            </b-col>
            <b-col cols="12" lg="6" class="order-4 order-lg-1">
              <img src="images/fesztival.png" class="w-100 margin-top-sm-44 mb-4"/>
          </b-col>
            <b-col cols="12" lg="10" class="offset-lg-1 side-gap order-lg-2 order-1">
            <p class="text">
              <ul>
                <li class="py-3 d-sm-flex">
                  <span class="number">1</span>
                  <p class="text"><b>Használj telefonos fizetést okostelefonoddal:</b> Egyre több fesztivál lehetővé teszi a telefonos fizetést a helyszínen. Ez könnyű és kényelmes módja annak, hogy vásároljunk ételt, italt vagy akár jegyeket. Azonban fontos, hogy megfelelő óvatosságot tanúsítsunk ezen a területen is. Győződjünk meg arról, hogy telefonunk biztonságosan zárható, és használjunk erős jelszavakat vagy ujjlenyomat-azonosítást a fizetési alkalmazásokhoz! Tartsuk frissen a telefonunk operációs rendszerét és az alkalmazásokat, hogy kihasználhassuk a legújabb biztonsági funkciókat!</p> 
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">2</span>
                  <p class="text"><b>Éljünk a fesztiválkártya lehetőségével:</b>Sok fesztivál kínál saját kártyát vagy zsetont mint saját fizetőrendszerük alapját, amellyel könnyebben vásárolhatunk a helyszínen. Ha használunk ilyen kártyát, fontos, hogy biztonságban tartsuk magunknál. Bár logikusnak tűnik, ne tároljuk együtt készpénzzel vagy fontos dokumentumokkal, mint például a személyi, és kerüljük a nyilvános helyeken történő hozzáférést, mint például a nyakba akasztható kártytartó használatát. Helyette gondoskodjunk saját kártyatartóról, ami akár hozzá van kötve a táskánk vagy zsebünk belsejéhez! Amikor lehúzzuk a kártyát, ügyeljünk arra, hogy mások ne láthassák a kódját (amennyiben van), és gondoskodjunk róla, hogy a kártyát megfelelően tároljuk (azaz nem károsítjuk) és biztonságban tartsuk.</p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">3</span>
                  <p class="text"><b>Figyeljünk a telefonunkra:</b> A fesztiválok zsúfolt helyszínei ideálisak a zsebtolvajok számára. Fontos, hogy óvjuk a telefonunkat és figyeljünk rá a tömegben. Használjunk biztonsági szíjat, amivel a táskánkhoz, kezünkhöz vagy zsebünkhöz rögzítjük, vagy használjunk egy táskát, amelyet testünk előtt tudunk tartani! Ne hagyjuk a telefont felügyelet nélkül asztalokon vagy más nyilvános felületeken, főként fontos alkalmazások megnyitása után és ne helyezzük el könnyen elérhető helyeken, például hátsó zsebben vagy nyitott táskában! Használjunk jelszót vagy ujjlenyomat-azonosítást a telefonunkhoz, hogy ne legyen könnyű hozzájutni a személyes adatokhoz!</p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">4</span>
                  <p class="text"><b>Ha mégis megtörtént a baj:</b> Barátaink, fesztiválcsapatunk tagjai letölthetik a FindMyPhone alkalmazást, vagy bármi hasonlót, amit előtte a telefonon engedélyeznünk kell. Bár már nem azokat az időket éljük, mégis hasznos emellet tudnunk mind saját, mind pár barátunk telefonszámát, vagy legalább egy cetlire felírni ezeket, amit egy telefonunktól távol eső helyen tárolunk. Ne pánikolj, ha elvesztetted a telefonodat, de jelents be az eltűnését mihamarabb mind a barátaidnak mind az erre szolgáló biztonsági felületen!</p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">5</span>
                  <p class="text"><b>Telefonszolgáltatások és adatforgalom:</b> Amikor részt veszünk egy fesztiválon, az esemény helyszínén a mobilhálózatok terheltek lehetnek, ami lassabb adatforgalmat és nehezebb hozzáférést eredményezhet. Ebben az esetben a Wi-Fi hálózatok is túlterheltek lehetnek, vagy akár biztonsági kockázatot is jelenthetnek. Ahhoz, hogy megelőzzük a problémákat, javasolt előre letölteni az applikációkat, offline térképeket vagy egyéb információkat, amelyekre szükségünk lehet a fesztivál ideje alatt. Kerüljük a nyilvános Wi-Fi hálózatok használatát, mert ezek kiszolgáltatottá tehetik a személyes adatainkat!</p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">6</span>
                  <p class="text"><b>Használjunk biztonságos internetkapcsolatot:</b> Amennyiben muszáj internethez kapcsolódni a fesztiválon, például az alkalmazások vagy közösségi média használatához, próbáljuk meg használni a saját mobiladat-kapcsolatunkat. Állítsunk be jelszót a telefonos hálózati hotspotunkhoz, hogy mások ne tudjanak illetéktelenül csatlakozni! </p>
                </li>
              </ul>
            </p>
        </b-col>
        </b-row>
    </b-container>
    <Articles :isFirst="false"/>
  </section>
</template>
<script>
import Articles from '../components/Articles.vue'

export default {
  components: {
    Articles
  },
  mounted(){

    this.setMetaTags({        
        title: "Okos tippek fesztiválozáshoz", 
        desc: "A fesztiválok időszaka mindig izgalmas és felejthetetlen élményeket tartogat számunkra, azonban a digitális világ térnyerésével együtt járnak olyan kihívások és előnyök is, amelyekre érdemes odafigyelni és kihasználni.", 
        keywords: null, 
        ogType: 'website', 
        ogTitle: "Okos tippek fesztiválozáshoz",
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc: "A fesztiválok időszaka mindig izgalmas és felejthetetlen élményeket tartogat számunkra, azonban a digitális világ térnyerésével együtt járnak olyan kihívások és előnyök is, amelyekre érdemes odafigyelni és kihasználni."        
    })
  }
}
</script>